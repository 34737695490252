import { axiosInstance } from '@/utils/axios';
class GlobalService {
  async getEnum() {
    return await axiosInstance.get('misc/enums');
  }
  async getJobTitles() {
    return await axiosInstance.get('misc/job-titles');
  }
  async getJobCategories() {
    return await axiosInstance.get('misc/job-categories');
  }
  async getCountries() {
    return await axiosInstance.get('misc/countries');
  }
  async getStates(country) {
    return await axiosInstance.get(`misc/countries/${country}/states`);
  }
  async getCompanyCategory() {
    return await axiosInstance.get('misc/company-categories');
  }
  async sendContactForm(payload) {
    return await axiosInstance.post('misc/contact-us/employer', payload);
  }
  async applyWithoutSignIn({id, data}) {
    return await axiosInstance.post(`misc/jobs/${id}/apply`, data);
  }
  async getAllJobs(params) {
    return await axiosInstance.get('misc/jobs', { params });
  }
  async sendReferralId(payload) {
    return await axiosInstance.post(`misc/interested-in-job`, payload);
  }
}
export default new GlobalService();
